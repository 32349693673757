import React, { useEffect, useState} from "react";
import { Link } from "react-router-dom";

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

function FilterComponent({ filterText, onFilter, onClear, t, currentLanguage }) {

    const [isScrolled, setIsScrolled] = useState(false);
    
        const handleScroll = () => {
            if (window.scrollY > 100) {
            setIsScrolled(true);
            } else {
            setIsScrolled(false);
            }
        };
    
    useEffect(() => {            
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

  return (
    <>
    <div className={`col-12 pesquisa-artigos ${isScrolled ? 'fixed-top fixSearch' : ''}`}>
                {typeof(filterText) === "string" ? (
                    <input className="form-control filtroartigos" id="search"
                    type="text"
                    placeholder={t(currentLanguage+'_pesquisa-placeholder-loja')}
                    aria-label="Search Input"
                    value={filterText}
                    onChange={onFilter}/>
                ) : (
                    <input className="form-control filtroartigos" id="search"
                    type="text"
                    placeholder={t(currentLanguage+'_pesquisa-placeholder-loja')}
                    aria-label="Search Input"
                    value={""}
                    onChange={onFilter}/>
                )}
                <button
                    type="button"
                    className="btn btn-secondary cleanfiltro"
                    onClick={onClear}
                >
                    {t(currentLanguage+'_limpar-loja')}
                </button>
            </div>
            <div className="clearfix"><br/></div>
            <div className="col-12 pesquisa-artigos">
                <ul>
                    <li>
                        <input type="checkbox" className="form-check-input" checked={filterText.runflat}
                            onChange={(e) => onFilter(e,"runflat")} id="op_runflat" name="runflat"/>
                        <label className="form-check-label" htmlFor="op_runflat"><span>&nbsp;{t(currentLanguage+'_run-flat-loja')}</span></label>
                    </li>
                    <li>
                        <input type="checkbox" className="form-check-input" checked={filterText.oldot}
                            onChange={(e) => onFilter(e, "oldot")} id="op_oldot"/>
                        <label className="form-check-label" htmlFor="op_oldot"><span>&nbsp;{t(currentLanguage+'_old-dot-loja')}</span></label>
                    </li>
                    <li>
                        <input type="checkbox" className="form-check-input" checked={filterText.ev}
                            onChange={(e) => onFilter(e, "ev")} id="op_ev"/>
                        <label className="form-check-label" htmlFor="op_ev"><span>&nbsp;{t(currentLanguage+'_ev-loja')}</span></label>
                    </li>
                    <li>
                        <div className="form-floating epoca-chs">
                            <select className="form-select" id="selectepoca" onChange={e => onFilter(e, e.target.value)} aria-label="Default select epoca">
                                <option value="" selected>{t(currentLanguage+'_todas-epocas-loja')}</option>
                                <option selected={filterText.epoca === "AllSeason"} value="AllSeason">{t(currentLanguage+'_all-season-loja')}</option>
                                <option selected={filterText.epoca === "Verão"} value="Verão">{t(currentLanguage+'_verao-loja')}</option>
                                <option selected={filterText.epoca === "Inverno"} value="Inverno">{t(currentLanguage+'_inverno-loja')}</option>        
                            </select>
                            <label htmlFor="selectepoca">{t(currentLanguage+'_tipo-pneu-loja')}</label>
                        </div>
                    </li>
                    <li>
                        <div className={`text-center buttonCartStore ${isScrolled ? 'fixed-top staySit' : ''}`}>
                            <Link className="text-center btn btn-danger cleanfiltro" to={VITE_PUBLIC_ROOT+`/${currentLanguage}/carrinho`}>
                                {t(currentLanguage+'_ver-carrinho')}
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
    </>
  )
}

export default FilterComponent